import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import SEO from "../components/seo"
import ScrollAnimation from 'react-animate-on-scroll';

import ImageCoaching from '../components/images/imageCoaching.js'
import ImageDevelopement from '../components/images/imageDevelopement.js'
import ImageClimat from '../components/images/imageClimat.js'
import ImageChange from '../components/images/imageChange.js'
import ImageConflit from '../components/images/imageConflit.js'

const Developpement = () => (
  <Layout>
    <SEO title="Développement des compétences | Gestion Mieux-Être" />
    <PageHeader
        header="Développement des compétences"
        text="Nous sommes complices des professionnels et gestionnaires qui souhaitent accroître leur impact et influence.  Nous offrons une gamme de solutions de développement sur mesure visant à accroître l'efficacité des dynamiques interpersonnelles.  "
        quote="Changez par vous-même avant de devoir le faire par obligation
            <span>- Jack Welch</span>"
        background="developpement"
         />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">
        <p class="service-presentation">Nous sommes fiers de participer activement au développement des leaders d’aujourd’hui et de demain.</p>

        </ScrollAnimation>
        <br /><br />
        <div className="flex-container2">
            <ScrollAnimation
                delay="10"
                animateIn="fadeIn"
                className="half">
            <div>
                <Link to="developpement/equipes-et-collaborateurs">

                    <ImageDevelopement />
                    <h6>Développement des compétences pour les équipes et collaborateurs</h6>
                </Link>
            </div>
            </ScrollAnimation>
            <ScrollAnimation
                delay="20"
                animateIn="fadeIn"
                className="half">
            <div>
                <Link to="developpement/leaders">
                    <ImageCoaching />
                    <h6>Développement des compétences pour les leaders</h6>
                </Link>
            </div>
            </ScrollAnimation>
        </div>
        <br /><br /><br />
        <ScrollAnimation
            delay="10"
            animateIn="fadeIn">
        <Link className="button-link" to="#">Nous joindre</Link>
        </ScrollAnimation>
        <br /><br />
    </div>
  </Layout>
)

export default Developpement
